import React, { useEffect, useState } from "react";
import SimpleInput from "../UI/Input/SimpleInput";
import classes from "./ContactInfoForm.module.css";
import Button from "../UI/Buttons/Button";
import useInput from "../../hooks/use-input";
import PhoneNumberInput from "../UI/Input/PhoneNumberInput/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import LoadingSpinner from "../UI/LoadingSpinner/LoadingSpinner";
import SlotPicker from "../UI/Input/SlotPicker/SlotPicker";
import Dropdown from "../UI/DropDown/DropDown";
import { getLmpLimits as getLmpLimit } from "../../utils/ui-utils";
import moment from "moment";

const isNotEmpty = (value) => value?.trim() !== "";
const isEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
// const isValidURL = (url) => {
//   const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
//   return urlRegex.test(url);
// };

const ContactInfoForm = (props) => {
  const {
    userData,
    onSubmit,
    className,
    isLoading,
    status,
    isRegForm,
    btnText,
    isSlotBooking,
    onDateChange,
    onTimeChange,
    initialSelectedDate,
    initialSelectedTime,
    dateOptions,
    timeOptions,
    isInternalTeam,
    disabledPregDuration,
    showOccupationDropDown,
    dropDownOptions,
    trackParenting,
    parentingLimit,
  } = props;


  const {
    value: nameValue,
    isValid: nameIsValid,
    hasError: nameHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
  } = useInput(isNotEmpty, userData?.name);

  const {
    value: whatsAppNumber,
    isValid: whatsAppNumberIsValid,
    hasError: whatsAppNumberHasError,
    valueChangeHandler: whatsAppNumberChangeHandler,
    inputBlurHandler: whatsAppNumberBlurHandler,
  } = useInput(
    (str) => isValidPhoneNumber(str || ""),
    userData?.whatsAppNumber
  );

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isEmail, userData?.email);

  const {
    value: lmpValue,
    isValid: lmpIsValid,
    hasError: lmpHasError,
    valueChangeHandler: lmpChangeHandler,
    inputBlurHandler: lmpBlurHandler,
  } = useInput(isNotEmpty, userData?.lmp);

  const {
    value: sbValue,
    isValid: sbIsValid,
    hasError: sbHasError,
    valueChangeHandler: sbChangeHandler,
    inputBlurHandler: sbBlurHandler,
  } = useInput(isNotEmpty, userData?.slotBooking);

  const {
    value: mopValue,
    isValid: mopIsValid,
    hasError: mopHasError,
    valueChangeHandler: mopChangeHandler,
    inputBlurHandler: mopBlurHandler,
  } = useInput(isNotEmpty, userData?.monthOfPregnancy);

  const {
    value: dobValue,
    isValid: dobIsValid,
    hasError: dobHasError,
    valueChangeHandler: dobChangeHandler,
    inputBlurHandler: dobBlurHandler,
  } = useInput((date) => {
    return moment().diff(moment(date, 'YYYY-MM-DD'), 'months', true) <= parentingLimit;
  }, userData?.dateOfBirth);

  const {
    value: dropdownValue,
    isValid: dropdownValueIsValid,
    hasError: dropdownValueHasError,
    valueChangeHandler: dropdownValueChangeHandler,
    inputBlurHandler: dropdownValueBlurHandler,
  } = useInput(isNotEmpty, userData?.occupationDetail);

  const {
    value: callingTimestampValue,
    isValid: callingTimestampIsValid,
    hasError: callingTimestampHasError,
    valueChangeHandler: callingTimestampChangeHandler,
    inputBlurHandler: callingTimestampBlurHandler,
  } = useInput(isNotEmpty, userData?.callingTimestamp);

  const {
    value: commentsValue,
    isValid: commentsIsValid,
    hasError: commentsHasError,
    valueChangeHandler: commentsChangeHandler,
    inputBlurHandler: commentsBlurHandler,
  } = useInput(isNotEmpty, userData?.comments);

  const {
    value: callRecordingUrl,
    isValid: callRecordingUrlIsValid,
    hasError: callRecordingUrlHasError,
    valueChangeHandler: callRecordingUrlChangeHandler,
    inputBlurHandler: callRecordingUrlBlurHandler,
  } = useInput((agr) => true, userData?.callRecordingUrl);

  let [formIsValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (
      nameIsValid &&
      emailIsValid &&
      whatsAppNumberIsValid &&
      ((isRegForm ? mopIsValid : lmpIsValid) || disabledPregDuration) &&
      (isSlotBooking ? sbIsValid : true) &&
      (isSlotBooking && isInternalTeam
        ? callingTimestampIsValid && dropdownValueIsValid && commentsValue
        : true) &&
      (trackParenting ? dobIsValid : true) &&
      (showOccupationDropDown ? dropdownValueIsValid : true)
    ) {
      setIsFormValid(true);
    }
  }, [
    nameIsValid,
    emailIsValid,
    whatsAppNumberIsValid,
    lmpIsValid,
    isRegForm,
    mopIsValid,
    disabledPregDuration,
    isSlotBooking,
    sbIsValid,
    isSlotBooking,
    isInternalTeam,
    callingTimestampIsValid,
    dropdownValueIsValid,
    showOccupationDropDown,
    dobIsValid,
    trackParenting,
    commentsValue,
    formIsValid,
  ]);
  console.log([
    nameIsValid,
    whatsAppNumberIsValid,
    mopIsValid,
    sbIsValid,
    callingTimestampIsValid,
    dropdownValueIsValid,
    dobIsValid,
    trackParenting,
    isInternalTeam,
    isSlotBooking,
    showOccupationDropDown,
    isSlotBooking,
    disabledPregDuration,
    isRegForm,
    commentsValue,
    formIsValid,
  ])
console.log(formIsValid)
  const submitHandler = (event) => {
    event.preventDefault();
    let _userData = {
      name: nameValue,
      whatsAppNumber,
      lmp: lmpValue,
      email: emailValue,
      monthOfPregnancy: mopValue,
      occupationDetail: dropdownValue,
    };

    if (isSlotBooking) {
      _userData = {
        ..._userData,
        slotBooking: sbValue,
        callingTimestamp: callingTimestampValue,
        comments: commentsValue,
        callRecordingUrl,
      };
    }

    if (trackParenting) {
      _userData = { ..._userData, dateOfBirth: dobValue };
    }

    if (!formIsValid) {
      return;
    }

    onSubmit(_userData);
  };

  const { minLMP, maxLMP } = getLmpLimit();

  return (
    <form
      className={`${classes.userForm} ${className}`}
      onSubmit={submitHandler}
    >
      <SimpleInput
        label="Name"
        type="text"
        id={"name"}
        showAsRequired={true}
        value={nameValue}
        onChange={nameChangeHandler}
        onBlur={nameBlurHandler}
        hasError={nameHasError}
        description={nameHasError && "Name must not be empty!."}
      />
      <SimpleInput
        label="Email"
        type="email"
        id={"email"}
        showAsRequired={true}
        value={emailValue}
        onChange={emailChangeHandler}
        onBlur={emailBlurHandler}
        hasError={emailHasError}
        description={
          emailHasError
            ? "Enter a valid email!."
            : !isRegForm &&
              "Enter the Email ID you will use while signing up on the iMumz App"
        }
      />
      <PhoneNumberInput
        label="WhatsApp Number"
        type="tel"
        id="tel"
        value={whatsAppNumber}
        onChange={whatsAppNumberChangeHandler}
        hasError={whatsAppNumberHasError}
        onBlur={whatsAppNumberBlurHandler}
        description={
          "Phone number Get important personalized updates via WhatsApp."
        }
      />

      {!disabledPregDuration && (
        <>
          {!isRegForm && (
            <SimpleInput
              label={"Last Menstrual Period (LMP)"}
              type="date"
              id={"date"}
              showAsRequired={true}
              value={lmpValue}
              onChange={lmpChangeHandler}
              hasError={lmpHasError}
              onBlur={lmpBlurHandler}
              description={
                "Important information to calculate your program price."
              }
              min={minLMP}
              max={maxLMP}
            />
          )}

          {isRegForm && (
            <SimpleInput
              label="Month Of Pregnancy"
              type="number"
              id={"mop"}
              showAsRequired={true}
              value={mopValue}
              onChange={mopChangeHandler}
              hasError={mopHasError}
              onBlur={mopBlurHandler}
              description={mopHasError && "Field can't be left empty!."}
            />
          )}
        </>
      )}

      {trackParenting && (
        <SimpleInput
          label="Date Of Birth"
          type="date"
          id={"dob"}
          showAsRequired={true}
          value={dobValue}
          onChange={dobChangeHandler}
          hasError={dobHasError}
          onBlur={dobBlurHandler}
          description={
            dobHasError && dobValue.length === 0
              ? "Field can't be left empty!."
              : `This program is exclusively for babies under ${parentingLimit} months old.`
          }
        />
      )}

      {showOccupationDropDown && dropDownOptions && (
        <Dropdown
          label="What describes you the best?"
          options={dropDownOptions}
          value={dropdownValue}
          onChange={dropdownValueChangeHandler}
          hasError={dropdownValueHasError}
          onBlur={dropdownValueBlurHandler}
          description={dropdownValueHasError && "Field can't be left empty!."}
        />
      )}
      {isSlotBooking && (
        <>
          <SlotPicker
            label="Choose a slot"
            type="text"
            id="slot-p"
            value={sbValue}
            onChange={sbChangeHandler}
            hasError={sbHasError}
            onBlur={sbBlurHandler}
            dateOptions={dateOptions}
            timeOptions={timeOptions}
            onDateChange={onDateChange}
            onTimeChange={onTimeChange}
            initialSelectedDate={initialSelectedDate}
            initialSelectedTime={initialSelectedTime}
            description={sbHasError && "Field can't be left empty!."}
          />

          {isInternalTeam && (
            <>
              <SimpleInput
                label="Calling Timestamp"
                type="datetime-local"
                id={"callingTimestamp"}
                showAsRequired={true}
                value={callingTimestampValue}
                onChange={callingTimestampChangeHandler}
                hasError={callingTimestampHasError}
                onBlur={callingTimestampBlurHandler}
                description={
                  callingTimestampHasError && "Field can't be left empty!."
                }
              />
              <SimpleInput
                label="Call Recording Url"
                type="url"
                id="callRecordingUrl"
                value={callRecordingUrl}
                onChange={callRecordingUrlChangeHandler}
                onBlur={callRecordingUrlBlurHandler}
                // showAsRequired={true}
                hasError={callRecordingUrlHasError}
                description={
                  callRecordingUrlHasError && "Field can't be left empty!."
                }
              />
              <SimpleInput
                label="Comments"
                element="textarea"
                id="comments"
                showAsRequired={true}
                value={commentsValue}
                onChange={commentsChangeHandler}
                onBlur={commentsBlurHandler}
                hasError={commentsHasError}
                description={commentsHasError && "Field can't be left empty!."}
              />
            </>
          )}
        </>
      )}

      {isLoading && <LoadingSpinner />}
      {status && <p className={classes.errorText}>{status}</p>}
      {!isLoading && (
        <Button className={classes.btn} type={"submit"} disabled={!formIsValid}>
          {btnText || "NEXT"}
        </Button>
      )}
    </form>
  );
};

export default ContactInfoForm;
