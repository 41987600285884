import React, { useState } from "react";
import ReactDOM from "react-dom";

import classes from "./PopUp.module.css";
import Card from "../../../Card/Card";
import Button from "../../../Buttons/Button";
import PopupItem from "./PopupItem/PopupItem";

export const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const ModalOverlay = ({
  dateOptions,
  timeOptions,
  onConfirm,
  onDateChange,
  onTimeChange,
  initialSelectedDate,
  initialSelectedTime,
}) => {
  const [selection, setSelection] = useState({
    initialSelectedDate,
    initialSelectedTime,
  });

  const onConfirmHandler = () => {
    onConfirm(selection);
  };

  const onDateSelectHandler = (data) => {
    setSelection({ ...selection, initialSelectedDate: data });
    onDateChange(data);
  };

  const onTimeSelectHandler = (data) => {
    setSelection({ ...selection, initialSelectedTime: data });
    onTimeChange(data);
  };

  return (
    <Card className={classes.modal}>
      <PopupItem
        title={"Choose Date"}
        options={dateOptions}
        initialValue={initialSelectedDate}
        onSelect={onDateSelectHandler}
        type={"date"}
      />
      <PopupItem
        title={"Choose time"}
        options={timeOptions}
        initialValue={initialSelectedTime}
        onSelect={onTimeSelectHandler}
        type={"time"}
      />
      <Button onClick={onConfirmHandler}>SELECT</Button>
    </Card>
  );
};

const PopUp = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onConfirm={props.onConfirm}
          onClose={props.onClose}
          dateOptions={props.dateOptions}
          timeOptions={props.timeOptions}
          onDateChange={props.onDateChange}
          onTimeChange={props.onTimeChange}
          initialSelectedDate={props.initialSelectedDate}
          initialSelectedTime={props.initialSelectedTime}
        />,
        document.getElementById("overlay-root")
      )}
    </>
  );
};

export default PopUp;
