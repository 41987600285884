import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  doctorName: "",
  patientName: "",
  report: [],
};
const prescriptionSlice = createSlice({
  initialState: initialState,
  name: "prescription",
  reducers: {
    reset: () => initialState,
    saveData(state, action) {
      state = action.payload
    },
  },
});

export default prescriptionSlice;
export const prescriptionActions = prescriptionSlice.actions;
