import React, { Suspense } from "react";
import Prescription from "../components/Prescription/Prescription";
import { json, defer, Await, useLoaderData } from "react-router-dom";
import { prescriptionActions } from "../store/slices/prescription-slice";
import store from "../store/store";

const data = {
  doctorName: "Dr Vani Raj",
  patientName: "Sakshi",
  reports: [
    {
      srNo: 1,
      type: "multi-para",
      data: {
        label: "Concern & Observation",
        content: {
          paras: [
            "Age: 31y5m. C/O of Anxious to conceive, married for 5 years. Irregular periods before marriage and got worsened after marriage. Used to get 40 -50 days cycle and at times cycle continues for 15 days or so.",
            "Menstrual cycle for the last 3 months says: 1/4/23[5 DAYS]; 1/5/23 [5 DAYS ]; 16/5/23 [ still continuing ] with lot of clots and cramps",
            "A: Erratic",
            "B: Tends to get constipated",
            "S: Late hours and wakes up tired and dull through out the day. days cycle and at times cycle continues for 15 days or so.",
          ],
        },
      },
    },
    {
      srNo: 2,
      type: "bullet-point",
      data: {
        label: "Final Advice",
        content: {
          points: [
            {
              main: "Diet",
              sub: [
                "Use more of vegetables [steamed or assorted with lot of colored veggies and greens for each meal].",
                "Avoid high proteins including all dals [except MOONG / GREEN GRAM], potato and brinjal.",
                "Completely avoid spicy, oily, fried, deep fried, Non-veg , curd, milk and milk products, stale food",
              ],
            },
            {
              main: "Compulsory 60 mins of exercise in the form of yoga designed by the preconception yoga expert to enhance fertility & reduction of stress",
              sub: undefined,
            },
            {
              main: "Diet",
              sub: [],
            },
            {
              main: "Compulsory 60 mins of exercise in the form of yoga designed by the preconception yoga expert to enhance fertility & reduction of stress",
            },
          ],
        },
      },
    },
    {
      srNo: 3,
      type: "table",
      data: {
        label: "Medicines",
        content: {
          rows: [
            {
              type: "th",
              data: ["Medicine Name", "Dosage", "Instructions", "Duration"],
            },
            {
              type: "td",
              data: [
                "KASHAYAM MUSALIKHADIRADI KASHAYAM (200 ml)",
                "15ml Kashyam + 60ml lukewarm water @6am and @6pm on empty stomach ",
                "Before food [for 7 days to stop bleeding and need not to take if bleeding stopped] ",
                "7 day(s)",
              ],
            },
            {
              type: "td",
              data: [
                "KASHAYAM MUSALIKHADIRADI KASHAYAM (200 ml)",
                "15ml Kashyam + 60ml lukewarm water @6am and @6pm on empty stomach ",
                "Before food [for 7 days to stop bleeding and need not to take if bleeding stopped] ",
                "7 day(s)",
              ],
            },
            {
              type: "td",
              data: [
                "KASHAYAM MUSALIKHADIRADI KASHAYAM (200 ml)",
                "15ml Kashyam + 60ml lukewarm water @6am and @6pm on empty stomach ",
                "Before food [for 7 days to stop bleeding and need not to take if bleeding stopped] ",
                "7 day(s)",
              ],
            },
            {
              type: "td",
              data: [
                "KASHAYAM MUSALIKHADIRADI KASHAYAM (200 ml)",
                "15ml Kashyam + 60ml lukewarm water @6am and @6pm on empty stomach ",
                "Before food [for 7 days to stop bleeding and need not to take if bleeding stopped] ",
                "7 day(s)",
              ],
            },
            {
              type: "td",
              data: [
                "KASHAYAM MUSALIKHADIRADI KASHAYAM (200 ml)",
                "15ml Kashyam + 60ml lukewarm water @6am and @6pm on empty stomach ",
                "Before food [for 7 days to stop bleeding and need not to take if bleeding stopped] ",
                "7 day(s)",
              ],
            },
            {
              type: "td",
              data: [
                "KASHAYAM MUSALIKHADIRADI KASHAYAM (200 ml)",
                "15ml Kashyam + 60ml lukewarm water @6am and @6pm on empty stomach ",
                "Before food [for 7 days to stop bleeding and need not to take if bleeding stopped] ",
                "7 day(s)",
              ],
            },
            {
              type: "td",
              data: [
                "KASHAYAM MUSALIKHADIRADI KASHAYAM (200 ml)",
                "15ml Kashyam + 60ml lukewarm water @6am and @6pm on empty stomach ",
                "Before food [for 7 days to stop bleeding and need not to take if bleeding stopped] ",
                "7 day(s)",
              ],
            },
          ],
        },
      },
    },
  ],
};

const PrescriptionPage = () => {
  const { report } = useLoaderData();

  return (
    <Suspense
      fallback={
        <p style={{ textAlign: "center", paddingTop: "50vh" }}>Loading...</p>
      }
    >
      <Await resolve={report}>
        {(loadedReport) => {
          console.log(loadedReport);
          return <Prescription data={loadedReport} />;
        }}
      </Await>
    </Suspense>
  );
};

const getPrescriptionReport = async (token) => {
  let response = await fetch(
    `https://us-central1-pruoo-app.cloudfunctions.net/getPreconceptionPrescription?token=${token}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    throw json({ message: "Couldn't get event's Info!" }, { status: 500 });
  }
  const data = (await response.json()).data;
  store.dispatch(prescriptionActions.saveData(data));
  return data;
};

export async function loader() {
  const searchParams = _useSearchParams();
  const token = searchParams.get("token");
  return defer({ report: getPrescriptionReport(token) });
}

const _useSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    get(key) {
      return searchParams.get(key);
    },
  };
};

export default PrescriptionPage;
