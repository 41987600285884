import React from 'react';

import classes from './Card.module.css';

const Card = (props) => {
  const cardType = props.cardType || "light-border";
  return (
    <div className={`${classes.card} ${classes[cardType]} ${props.className}`} onClick={props.onClick}>{props.children}</div>
  );
};

export default Card;
