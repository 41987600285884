import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../store/slices/user-slice";
import { appendFormDataIntoSheet } from "../store/custom-actions-creator";
import SpForm from "../components/SpForm/SpForm";

const SpUserForm = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  const onSubmitHandler = (userData) => {
    window.dataLayer.push(userData);
    dispatch(userActions.saveFormData(userData));
    const searchParams = new URLSearchParams(search);
    searchParams.set("lmp", userData?.lmp || "");
    const newSearch = searchParams.toString();
    dispatch(appendFormDataIntoSheet("standardprogram_payments"));
    navigate(`plans?${newSearch}`);
    console.log(userData);
  };


  return <SpForm onSubmit={onSubmitHandler} userData={userData} />;
};

export default SpUserForm;
