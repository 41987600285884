import React from "react";
import classes from "./SlotBooking.module.css";
import cover from "../../resources/cover.svg";
import logo_white from "../../resources/imumz_icon.svg";
import logo_black from "../../resources/imumz_icon_black.svg";
import ContactInfoForm from "../ContactInfoForm/ContactInfoForm";

const SlotBooking = (props) => {
  const {
    userData,
    onSubmit,
    isLoading,
    status,
    btnText,
    dateOptions,
    timeOptions,
    onDateChange,
    onTimeChange,
    initialSelectedDate,
    initialSelectedTime,
    isInternalTeam,
    dropDownOptions,
  } = props;
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img className={classes.logo} src={logo_white} alt="imumz_logo" />
        <img className={classes.cover} src={cover} alt="pregnant Lady" />
      </div>
      <div className={classes.formContainer}>
        <img className={classes.logoBlack} src={logo_black} alt="imumz_logo" />
        <hr className={`solid ${classes.divider}`} />
        <h1 className={classes.heading}>
          Book a call with our Pregnancy Expert
        </h1>
        <ContactInfoForm
          className={classes.form}
          userData={userData}
          onSubmit={onSubmit}
          isLoading={isLoading}
          status={status}
          btnText={btnText}
          dateOptions={dateOptions}
          timeOptions={timeOptions}
          onDateChange={onDateChange}
          onTimeChange={onTimeChange}
          initialSelectedDate={initialSelectedDate}
          initialSelectedTime={initialSelectedTime}
          isSlotBooking={true}
          isInternalTeam={isInternalTeam}
          showOccupationDropDown={true}
          dropDownOptions={dropDownOptions}
        />
      </div>
    </div>
  );
};

export default SlotBooking;
