import { Suspense } from "react";
import store from "../store/store";
import {
  useRouteLoaderData,
  json,
  defer,
  Await,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { PlansActions } from "../store/slices/plans-slice";
import PlanSelection from "../components/PlanSelection/PlanSelection";
import { useDispatch, useSelector } from "react-redux";
import { couponActions } from "../store/slices/coupon-slice";
import * as CustomActionsCreator from "../store/custom-actions-creator";
import { userActions } from "../store/slices/user-slice";
import { useEffect } from "react";
import { UiActions } from "../store/slices/ui-slice";
import LayoutFrame2 from "../components/UI/MainLayout/LayoutFrames/LayoutFrame2";
import pregMum from "../resources/preg_mums.svg";
import { validateDate } from "../utils/ui-utils";

function WcpPlans() {
  // const { plans } = useLoaderData();
  const { plans } = useRouteLoaderData("program-plans");
  const [searchParams] = useSearchParams();
  const lmp = searchParams.get("lmp"); // similar for the rest of query para
  const planTypeFilter = searchParams.get("planTypeFilter");
  const isRenewal = searchParams.get("isRenewal") === "true";
  const coupon = useSelector((state) => state.coupon);
  const userData = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.ui.isLoading);
  const status = useSelector((state) => state.ui.status);
  const isCouponInputVisible = useSelector(state => state.ui.isCouponInputVisible)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
     
    dispatch(UiActions.setShouldShowHeaders(true && !isRenewal));
    dispatch(userActions.setLmp(lmp));
    dispatch(UiActions.setProgramType("wcp"));
  }, [lmp, dispatch,isRenewal]);

  const shouldExecuteRazorpayScript = useSelector(
    (state) => state.ui.shouldExecuteRazorpayScript
  );

  useEffect(() => {
    console.log([shouldExecuteRazorpayScript, dispatch, userData.extras.order]);
    if (shouldExecuteRazorpayScript) {
      dispatch(CustomActionsCreator.openPlanPurchasePaymentPage("WCP"));
    }
  }, [shouldExecuteRazorpayScript, dispatch, userData.extras.order]);

  const onPayNow = () => {
    console.log("paynowing...");
    if (
      userData.name === "" ||
      userData.whatsAppNumber === "" ||
      userData.lmp === "" ||
      userData.email === ""
    ) {
      navigate("/" + location.search);
      return;
    }
    const dataToSync = coupon.isApplied
      ? {
          ...userData,
          appliedCoupon: coupon.code,
        }
      : userData;

    dispatch(
      CustomActionsCreator.createUserDocument(
        "premium_womb_care_program_plans",
        dataToSync
      )
    );
    dispatch(UiActions.setShouldExecuteRazorpayScript(true));
  };

  const onPlanSelect = (selectedPlanId) => {
    dispatch(userActions.saveSelectedPlanId(selectedPlanId));
  };

  const onSubmitCouponCode = (couponCode) => {
    dispatch(couponActions.setCode(couponCode));
    dispatch(CustomActionsCreator.applyCouponCode({ couponCode }));
    console.log("cCode : " + couponCode);
  };

  const onRemoveCoupon = () => {
    dispatch(couponActions.reset());
  };

  const Component = (plans) => {
    return (
      <PlanSelection
        frame={
          <LayoutFrame2
            title="WombCare"
            subtitle="PROGRAM"
            whiteText1="Make your pregnancy easier with"
            colouredText="Yoga, Nutrition & Garbha Sanskar Coaches"
            whiteText2="by your side."
            image={pregMum}
            theme="blue"
          />
        }
        plans={plans}
        lmp={lmp}
        onProceed={onPayNow}
        onCouponSubmit={onSubmitCouponCode}
        enableCouponBtn={!coupon.isApplied}
        isApplyingCoupon={coupon.isProcessing}
        cCode={coupon.code}
        couponHasError={!coupon.isValid}
        discountedPlanId={coupon.discountedPlanId}
        discount={coupon.discount}
        promoCodePrompt={coupon.prompt}
        discountOnPerMonthPlan={coupon.discountOnPerMonthPlan}
        onPlanSelect={onPlanSelect}
        btnText={status || "Pay Now"}
        disableSubmitBtn={isLoading}
        onRemoveCoupon={onRemoveCoupon}
        shouldShowPrompt={
          planTypeFilter ? planTypeFilter === "full-plan" : true
        }
        title={"Choose your plan for iMumz Wombcare Program"}
        isRenewal={isRenewal}
        isCouponInputVisible={isCouponInputVisible}
      />
    );
  };

  return (
    <Suspense
      fallback={
        <p style={{ textAlign: "center", paddingTop: "50vh" }}>Loading...</p>
      }
    >
      <Await resolve={plans}>{(loadedPlans) => Component(loadedPlans)}</Await>
    </Suspense>
  );
}

export default WcpPlans;

const getPlans = async (lmp, programStartDate, plantTypeFilter) => {
  const plans = store.getState().plans.plans;
  const newLmp = store.getState().user.lmp;
  const oldLmp = store.getState().plans.lmp;
  if (plans && plans.length > 0 && newLmp === oldLmp) {
    return plans;
  }
  console.log("making req...");
  let response = await fetch(
    `https://us-central1-pruoo-app.cloudfunctions.net/getPregnancyPlans?lmp=${lmp}&programStartDate=${
      programStartDate || ""
    }&planTypeFilter=${plantTypeFilter || ""}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    throw json({ message: "Couldn't get list of Plans!" }, { status: 500 });
  }
  const data = await response.json();
  console.log(data);
  if (data.result === "success") {
    const { lmp, plans } = data.data;
    store.dispatch(PlansActions.savePlans(plans));
    store.dispatch(PlansActions.saveLmp(lmp));
    return plans;
  }
  throw json(
    { message: data.message || "Couldn't get list of Plans!" },
    { status: 500 }
  );
};

export async function loader({ request }) {
  const url = new URL(request.url);
  const lmp = url.searchParams.get("lmp");
  const programStartDate = validateDate(
    url.searchParams.get("programStartDate")
  );
  const planTypeFilter = url.searchParams.get("planTypeFilter");
  return defer({
    plans: getPlans(lmp, programStartDate, planTypeFilter),
  });
}
export function delay(t, data) {
  return new Promise((resolve) => {
    setTimeout(resolve, t, data);
  });
}
