import React from "react";
import classes from "./SpForm.module.css";
import MainLayout from "../UI/MainLayout/MainLayout";
import ContactInfoForm from "../ContactInfoForm/ContactInfoForm";
import ladyBabyheart from "../../resources/standardMom.svg";
import LayoutFrame2 from "../UI/MainLayout/LayoutFrames/LayoutFrame2";
import LayoutFrame1 from "../UI/MainLayout/LayoutFrames/LayoutFrame1";
const SpForm = (props) => {
  const { onSubmit, userData } = props;
  return (
    <MainLayout
      title={"Let's get you started"}
      content={
        <ContactInfoForm
          className={classes.form}
          onSubmit={onSubmit}
          userData={userData}
          btnText={"NEXT"}
        />
      }
      frame={
        // <div className={classes.gradientDiv}>
          <LayoutFrame1
            title="Standard"
            subtitle="PROGRAM"
            whiteText1="Your All-in-One Pregnancy Guide with"
            colouredText="daily garbhasanskar activities"
            whiteText2="for healthy, happy & intelligent baby"
            image={ladyBabyheart}
            theme="green"
          />
        // </div>
      }
    />
  );
};

export default SpForm;
