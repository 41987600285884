import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  email: "",
  whatsAppNumber: "",
  lmp: "",
  slotBooking: "",
  userId: null,
  selectedPlanId: null,
  comments: "",
  occupationDetail: "",
  callingTimestamp: "",
  callRecordingUrl: "",
  extras: { source: "website" },
  monthOfPregnancy: "",
  programStartDate: null,
  dateOfBirth : "",
  isRenewal: false,
};
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setLmp(state, actions) {
      state.lmp = actions.payload;
    },
    saveUserId(state, action) {
      state.userId = action.payload;
    },
    saveSelectedPlanId(state, action) {
      state.selectedPlanId = action.payload;
    },
    saveFormData(state, action) {
      const {
        name,
        email,
        whatsAppNumber,
        lmp,
        monthOfPregnancy,
        slotBooking,
        comments,
        occupationDetail,
        callingTimestamp,
        callRecordingUrl,
        dateOfBirth
      } = action.payload;
      state.name = name;
      state.email = email;
      state.whatsAppNumber = whatsAppNumber;
      state.lmp = lmp;
      state.monthOfPregnancy = monthOfPregnancy;
      state.comments = comments;
      state.occupationDetail = occupationDetail;
      state.callingTimestamp = callingTimestamp;
      state.slotBooking = slotBooking;
      state.callRecordingUrl = callRecordingUrl;
      state.dateOfBirth = dateOfBirth
    },
    setExtras(state, actions) {
      state.extras = actions.payload;
    },
    appendPropsInExtras(state, actions) {
      state.extras = { ...(state?.extras || {}), ...actions.payload };
    },
    saveValueWithKey(state, actions) {
      state[actions.payload.key] = actions.payload.value;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;
