import {
  appendDataIntoSheet,
  bookSlot,
  createOrder,
  getIpData,
  validateCoupons,
} from "../lib/api";
import { UiActions } from "./slices/ui-slice";
import { userActions } from "./slices/user-slice";
import { handlePayment } from "../utils/payment-utils";
import store from "./store";
import { couponActions } from "./slices/coupon-slice";
import {
  calculateRemainingDuration,
  filterObject,
  getCurrentDate,
} from "../utils/ui-utils";
import { ipActions } from "./slices/ip-slice";
import moment from "moment";

export const createUserDocument = (paymentType, data) => {
  return async (dispatch) => {
    dispatch(UiActions.setError(null));
    dispatch(UiActions.setStatus("Sending UserDetails..."));
    dispatch(UiActions.setIsLoading(true));
    try {
      dispatch(UiActions.setStatus("Preparing Payment Gateway..."));
      let payload = {
        paymentType,
        selectedPlanId: data.selectedPlanId,
        name: data.name,
        email: data.email,
        whatsAppNumber: data.whatsAppNumber,
        lmp: data.lmp,
        utmSource: data.extras.utm_source,
        utmCampaign: data.extras.utm_campaign,
        utmContent: data.extras.utm_content,
        programStartDate: data.programStartDate,
        appliedCoupon: data?.appliedCoupon,
      };

      

      if (data.isRenewal === "true") {
        payload = {
          ...payload,
          extraNotes: {
            isRenewal: data.isRenewal,
          },
        };
      }

      if( data?.dateOfBirth &&  data?.dateOfBirth.trim().length > 0){
        payload = {
          ...payload,
          extraNotes: {
            ...payload.extraNotes,
            dateOfBirth : data?.dateOfBirth
          },
        };
      }

      const orderDetails = await createOrder(payload);
      dispatch(userActions.appendPropsInExtras(orderDetails));
      dispatch(UiActions.setShouldExecuteRazorpayScript(true));

      dispatch(UiActions.setStatus(null));
      dispatch(UiActions.setIsLoading(false));
    } catch (err) {
      dispatch(UiActions.setIsLoading(false));
      dispatch(
        UiActions.setError(
          err.message || "Couldn't save users Data!.Try after a while."
        )
      );
    }
  };
};

export const openPlanPurchasePaymentPage = (programType) => {
  return async (dispatch) => {
    const userData = store.getState().user;
    const cCodeIsValid = store.getState().coupon.isValid;
    const cCode = store.getState().coupon.code;
    const remainingDuration = calculateRemainingDuration(userData.lmp);
    const selectedPlan = store
      .getState()
      .plans.plans.find((p) => p._id === userData.selectedPlanId);
    window.fbq("track", "Lead", { ...userData });

    let notes = {};

    if (userData.lmp && userData.lmp.trim().length > 0) {
      notes = {
        ...userData.extras,
        appliedCoupon: cCodeIsValid ? cCode : "",
        plan: userData.selectedPlanId,
        actualAmountToPay: userData.extras.amount,
        lmpOrEdd: userData.lmp,
        programStartDate: userData.programStartDate
          ? moment.utc(userData.programStartDate).format()
          : new Date().toJSON(),
        programEndDate: new Date(
          new Date().getTime() +
            Number(selectedPlan?.validityInDays) * 24 * 60 * 60 * 1000
        ).toJSON(),
        numberOfMonthsPayFor:
          selectedPlan.planType === "time-bound"
            ? +selectedPlan.validityInDays === 93
              ? "3 months"
              : "1 month"
            : `${remainingDuration.remainingMonths} months & ${remainingDuration.remainingDays} days`,
        source: "website",
        paymentTermType: selectedPlan.planType,
        PlanValidityInDays: selectedPlan.validityInDays,
      };
    } else {
      notes = { ...userData, ...userData.extras };
    }

    await handlePayment(
      {
        order: userData.extras.order,
        amount: userData.extras.amount,
      },
      userData,
      filterObject(notes),
      (data) => {
        dispatch(userActions.appendPropsInExtras({ appliedCoupon: cCode }));
        dispatch(userActions.appendPropsInExtras(data));
        dispatch(UiActions.setIsPaymentDone(true));
        dispatch(UiActions.setIsLoading(false));
        dispatch(userActions.appendPropsInExtras({ order: "", amount: "" }));
        // dispatch(UiActions.setShouldExecuteRazorpayScript(false))
      },
      () => {
        dispatch(UiActions.setShouldExecuteRazorpayScript(false));
        dispatch(UiActions.setIsLoading(false));
        dispatch(userActions.appendPropsInExtras({ order: "", amount: "" }));
      },
      programType || "WCP" //TODO : remove default value
    );
  };
};

export const openProgramRegistrationPaymentPage = (paymentType) => {
  return async (dispatch) => {
    //create order
    dispatch(UiActions.setIsLoading(true));
    dispatch(UiActions.setStatus("Please wait..."));
    try {
      const orderDetails = await createOrder({
        paymentType,
        extraNotes: {
          date: store.getState().event.date,
          time: store.getState().event.time,
        },
      });
      console.log("something " + JSON.stringify(orderDetails));
      dispatch(userActions.appendPropsInExtras(orderDetails));
      dispatch(UiActions.setShouldExecuteRazorpayScript(true));
      dispatch(UiActions.setIsLoading(false));
      dispatch(UiActions.setStatus(null));
    } catch (err) {
      dispatch(UiActions.setIsLoading(false));
      dispatch(UiActions.setStatus(err.message));
    }
    const userData = store.getState().user;
    await handlePayment(
      {
        order: userData.extras.order,
        amount: userData.extras.amount,
      },
      userData,
      filterObject({
        ...userData,
        ...userData.extras,
      }),
      () => {
        dispatch(UiActions.setIsPaymentDone(true));
        window.fbq("track", "Lead", { ...userData });
        // dispatch(UiActions.setShouldExecuteRazorpayScript(false))
      },
      () => {
        dispatch(UiActions.setShouldExecuteRazorpayScript(false));
        dispatch(UiActions.setIsLoading(false));
      },
      paymentType
    );
  };
};

export const applyCouponCode = () => {
  return async (dispatch) => {
    const { isApplied, isProcessing } = store.getState().coupon;
    const { lmp, selectedPlanId } = store.getState().user;
    //if already applied set prompt and not processing  ("already plied") return
    if (isApplied) {
      dispatch(couponActions.setPrompt("Coupon is Already Applied!"));
      return;
    }
    //if processing return
    if (isProcessing) {
      return;
    }

    dispatch(couponActions.setIsProcessing(true));
    dispatch(couponActions.setPrompt("Applying..."));
    const cCode = store.getState().coupon.code;
    const programStartDate = store.getState().user.programStartDate;
    const couponData = await validateCoupons(
      cCode,
      selectedPlanId,
      lmp,
      programStartDate
    );
    console.log(couponData);
    if (couponData && couponData.isValid) {
      console.log("something..");
      console.log(couponData);
      dispatch(couponActions.setIsValid(true));
      dispatch(couponActions.setError(null));
      dispatch(couponActions.setDiscount(couponData.discount));
      dispatch(
        couponActions.setDiscountOnPerMonthPlan(
          couponData.discountOnPerMonthPlan
        )
      );
      dispatch(couponActions.setDiscountedPlanId(couponData.discountedPlanId));
      dispatch(couponActions.setIsApplied(true));
      dispatch(couponActions.setPrompt("Coupon applied !!"));
    } else {
      dispatch(couponActions.setIsValid(false));
      dispatch(
        couponActions.setPrompt(
          "The coupon code entered is not valid for this plan. Perhaps you used the wrong coupon code?"
        )
      );
    }
    //set processing false
    dispatch(couponActions.setIsProcessing(false));
  };
};

export const appendFormDataIntoSheet = (sheetName, extrasColValues) => {
  return async (dispatch) => {
    const userData = store.getState().user;
    const ipData = store.getState().ip.data;
    const name = userData.name;
    const email = userData.email;
    const whatsAppNumber = userData.whatsAppNumber;
    const lmp = userData.lmp;
    const monthOfPreg = userData?.monthOfPregnancy;
    const utm_campaign = userData.extras["utm_campaign"] ||"n/a";
    const utm_source = userData.extras["utm_source"] || "n/a";
    const model = userData.extras["model"];
    const brand = userData.extras["brand"];
    let _notes = [
      getCurrentDate(),
      name,
      email,
      whatsAppNumber,
      utm_campaign || "",
      utm_source || "",
      lmp || monthOfPreg,
    ];
    if (extrasColValues && extrasColValues.length > 0) {
      console.log("Somethinggg ")
      console.log(extrasColValues);
      _notes = [..._notes, ...extrasColValues];
    }
    let notes = [
      [..._notes, ipData.city, ipData.region, model, brand].filter(value => value.trim().length >0),
      // .filter(
      //   (p) => p.toString().trim().length !== 0
      // ),
    ];
    const res = { notes, sheetName };
    await appendDataIntoSheet(res);
  };
};

export const requestToBookASlot = (isInternalTeam) => {
  return async (dispatch) => {
    dispatch(UiActions.setIsLoading(true));
    dispatch(UiActions.setStatus("Submitting Form..."));
    const state = store.getState();
    const data = {
      name: state.user.name,
      email: state.user.email,
      whatsAppNumber: state.user.whatsAppNumber,
      lmp: state.user.lmp,
      slotBooking: state.user.slotBooking,
      slotDocId: state.slots.newDate.id,
      slotId: state.slots.newTime.id,
      utmSource: state.user.extras.utm_source,
      utmCampaign: state.user.extras.utm_campaign,
      model: state.user.extras.model,
      brand: state.user.extras.brand,
      ipData: state.ip.data,
      isInternalTeam,
      interTeamData: {
        callingTimestamp: state.user.callingTimestamp,
        comments: state.user.comments,
        callRecordingUrl: state.user.callRecordingUrl,
        occupationDetail: state.user.occupationDetail,
        preSalesOwner: state.user.extras.pre_sales_owner,
      },
    };
    const res = await bookSlot(data);
    if (res.result === "success") {
      dispatch(UiActions.setIsSlotBooked(true));
    } else {
      dispatch(UiActions.setError(res.message));
    }
    dispatch(UiActions.setIsLoading(false));
    dispatch(UiActions.setStatus(null));
  };
};

export const traceIpAddress = () => async (dispatch) => {
  const ipData = await getIpData();
  console.log(ipData);
  if (ipData) dispatch(ipActions.setData(ipData));
  else dispatch(ipActions.setData({}));
};
