import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../store/slices/user-slice";
import {
  appendFormDataIntoSheet,
  openProgramRegistrationPaymentPage,
} from "../store/custom-actions-creator";
import { UiActions } from "../store/slices/ui-slice";
import { eventActions } from "../store/slices/event-slice";
import { useLoaderData } from "react-router";
import { json, defer, Await, useSearchParams } from "react-router-dom";
import RegistrationForm from "../components/RegistrationForm/RegistrationForm";
import store from "../store/store";

const Workshop = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.ui.isLoading);
  const status = useSelector((state) => state.ui.status);

  const [searchParams] = useSearchParams();
  const workshopName = searchParams.get("workshopName");
  console.log(workshopName);

  useEffect(() => {
    dispatch(UiActions.setIsBookingClasses(true));
  }, [dispatch]);

  const onSubmitHandler = (userData) => {
    window.dataLayer.push(userData);
    dispatch(userActions.saveFormData(userData));
    dispatch(
      appendFormDataIntoSheet(workshopName, [userData.occupationDetail])
    );
    window.fbq("track", "paymentPageOpen");
    dispatch(openProgramRegistrationPaymentPage(workshopName));
  };

  const { event } = useLoaderData();

  const {
    date,
    time,
    price,
    title,
    dropDownOptions,
    sideImage,
    showOccupationDropDown,
    disabledPregDuration,
    trackParenting,
    parentingLimit,
  } = useSelector((state) => state.event);

  console.log(dropDownOptions);

  return (
    <Suspense
      fallback={
        <p style={{ textAlign: "center", paddingTop: "50vh" }}>Loading...</p>
      }
    >
      <Await resolve={event}>
        {(loadedPlans) => {
          console.log(loadedPlans);
          return (
            <RegistrationForm
              onSubmit={onSubmitHandler}
              userData={userData}
              date={date}
              time={time}
              isLoading={isLoading}
              status={status}
              price={price}
              title={title}
              disabledPregDuration={disabledPregDuration}
              dropDownOptions={dropDownOptions}
              showOccupationDropDown={showOccupationDropDown}
              sideImage={sideImage}
              trackParenting={trackParenting}
              parentingLimit={parentingLimit}
            />
          );
        }}
      </Await>
    </Suspense>
  );
};

export default Workshop;

const getEventDetails = async (reqBody) => {
  let response = await fetch(
    `https://us-central1-pruoo-app.cloudfunctions.net/getDynamicVariable`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    }
  );
  // await delay(2000);
  if (!response.ok) {
    throw json({ message: "Couldn't get event's Info!" }, { status: 500 });
  }
  const data = await response.json();
  console.log(data);
  store.dispatch(eventActions.saveEvent(data));
  return data;
};

export async function loader() {
  const searchParams = _useSearchParams();
  const name = searchParams.get("workshopName");
  return defer({ event: getEventDetails({ name }) });
}
export function delay(t, data) {
  return new Promise((resolve) => {
    setTimeout(resolve, t, data);
  });
}

const _useSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    get(key) {
      return searchParams.get(key);
    },
  };
};
