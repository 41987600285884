import React from "react";
import classes from "./LayoutFrames.module.css";
import imumzIcon from "../../../../resources/imumIcon.svg";

const LayoutFrame1 = (props) => {
  const { title, subtitle, whiteText1, colouredText, whiteText2, image, theme } =
    props;
  return (
    <div
      className={`${classes.imageContainer} ${
        theme === "green" ? classes.greenBG : classes.blueBG
      }`}
    >
      <div className={classes.textContainer}>
        <div className={`${classes.img_titleContainer} ${theme==="green" ? classes.green_text_color : classes.blue_text_color}`}>
          <img
            alt="imumzIcon"
            className={classes.img_imumzIcon}
            src={imumzIcon}
          ></img>
          <p className={`${classes.img_title}`}>{title}</p>
          <p className={`${classes.img_subtitle}`}>{subtitle}</p>
        </div>
        <div className={classes.img_descriptionContainer}>
          {whiteText1.split(" ").map((str) => (
            <p key={str} className={classes.description_n}>
              {str}
            </p>
          ))}
          {colouredText.split(" ").map((str) => (
            <p
              key={str}
              className={ theme === "green" ? classes.green_highlighted_text : classes.blue_highlighted_text}
            >
              {str}
            </p>
          ))}
          {whiteText2.split(" ").map((str) => (
            <p className={classes.description_n}>{str}</p>
          ))}
        </div>
      </div>
      <img alt="preg_women" className={classes.mainImage} src={image}></img>
    </div>
  );
};

export default LayoutFrame1;
