import React, { Suspense } from "react";
import RegistrationForm from "../components/RegistrationForm/RegistrationForm";
import { useDispatch, useSelector } from "react-redux";
import { Await, defer, useLoaderData, json } from "react-router-dom";
import sideImage from "../resources/super_parenting.svg";
import { PlansActions } from "../store/slices/plans-slice";
import store from "../store/store";
import { userActions } from "../store/slices/user-slice";
import * as CustomActionsCreator from "../store/custom-actions-creator";
import { UiActions } from "../store/slices/ui-slice";
import { useEffect } from "react";

const SuperParentingForm = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.ui.isLoading);
  const { status, error } = useSelector((state) => state.ui);
  const { plans } = useLoaderData();

  const onSubmitHandler = (data) => {
    dispatch(userActions.saveFormData(data));
    dispatch(
      CustomActionsCreator.appendFormDataIntoSheet("super-parenting", [
        data.dateOfBirth,
      ])
    );
    dispatch(
      CustomActionsCreator.createUserDocument("superparenting_website_plans", {
        ...userData,
        ...userData.extras,
        ...data,
      })
    );
    dispatch(UiActions.setShouldExecuteRazorpayScript(true));
  };
  const shouldExecuteRazorpayScript = useSelector(
    (state) => state.ui.shouldExecuteRazorpayScript
  );

  useEffect(() => {
    console.log([shouldExecuteRazorpayScript, dispatch, userData.extras.order]);
    if (shouldExecuteRazorpayScript) {
      dispatch(
        CustomActionsCreator.openPlanPurchasePaymentPage("SuperParenting")
      );
    }
  }, [shouldExecuteRazorpayScript, dispatch, userData.extras.order]);

  return (
    <Suspense
      fallback={
        <p style={{ textAlign: "center", paddingTop: "50vh" }}>Loading...</p>
      }
    >
      <Await resolve={plans}>
        {(loadedPlans) => {
          console.log(loadedPlans);
          return (
            <RegistrationForm
              onSubmit={onSubmitHandler}
              userData={userData}
              boldTitle={`iMumz ${loadedPlans[0].title} Super Parenting.`}
              isLoading={isLoading}
              status={error || status}
              price={loadedPlans[0].price}
              disabledPregDuration={true}
              sideImage={sideImage}
              trackParenting={true}
              parentingLimit={24}
            />
          );
        }}
      </Await>
    </Suspense>
  );
};

export default SuperParentingForm;

const getPlansPrice = async (plan_duration) => {
  let response = await fetch(
    `https://us-central1-pruoo-app.cloudfunctions.net/getSuperParentingPlans?planTypeFilter=${plan_duration}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    throw json({ message: "Couldn't get event's Info!" }, { status: 500 });
  }
  const data = (await response.json()).data.plans;
  console.log(data);
  store.dispatch(PlansActions.savePlans(data));
  store.dispatch(userActions.saveSelectedPlanId(data[0]._id));
  return data;
};

export async function loader() {
  const searchParams = _useSearchParams();
  const plan_duration = searchParams.get("plan_duration") || "3m";
  return defer({ plans: getPlansPrice(plan_duration) });
}
export function delay(t, data) {
  return new Promise((resolve) => {
    setTimeout(resolve, t, data);
  });
}

const _useSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    get(key) {
      return searchParams.get(key);
    },
  };
};
