import { useCallback, useReducer } from "react";

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    console.log(action.value)
    return { value: action.value, isTouched: state.isTouched };
  }
  if (action.type === "BLUR") {
    return { isTouched: true, value: state.value };
  }
  if (action.type === "RESET") {
    return { isTouched: false, value: "" };
  }
  return inputStateReducer;
};

const useInput = (validateValue, initialValue) => {
  const [inputState, dispatch] = useReducer(inputStateReducer, {
    value: initialValue || "",
    isTouched: false,
  });

  const valueIsValid = validateValue(inputState.value);
  const hasError = !valueIsValid && inputState.isTouched;

  const valueChangeHandler =useCallback((event) => {
    dispatch({ type: "INPUT", value: event.target.value });
  }, []) ;

  const inputBlurHandler = useCallback((event) => {
    dispatch({ type: "BLUR" });
  },[]);

  const reset = useCallback(() => {
    dispatch({ type: "RESET" });
  },[]);

  return {
    value: inputState.value,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    inputBlurHandler,
    reset,
  };
};

export default useInput;
