import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: null,
  isLoading: false,
  error: null,
  isCouponInputVisible : false,
  shouldExecuteRazorpayScript: false,
  isPaymentDone : false,
  isBookingClasses : false,
  isSlotBooked : false,
  isSlotBooking : false,
  shouldShowHeader : false,
  programType : null
};
const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    reset :() => initialState,
    setProgramType(state,actions){
      state.programType = actions.payload
    },
    setShouldShowHeaders(state,actions){
      state.shouldShowHeader = actions.payload
    },
    setIsSlotBooking(state,action){
      state.isSlotBooking = action.payload
    },
    setIsSlotBooked(state,action){
      state.isSlotBooked = action.payload
    },
    setIsBookingClasses(state,actions){
      state.isBookingClasses = actions.payload
    },
    setIsLoading(state, actions) {
      state.isLoading = actions.payload;
    },
    setError(state, actions) {
      state.error = actions.payload;
    },
    setStatus(state, actions) {
      state.status = actions.payload;
    }, 
    setShouldExecuteRazorpayScript(state, actions) {
    state.shouldExecuteRazorpayScript = actions.payload;
  },
  setIsPaymentDone(state,actions){
    state.isPaymentDone = actions.payload
  },
  setCouponInputVisibility(state,actions){
    state.isCouponInputVisible = actions.payload
  }
  },
 
});

export const UiActions = uiSlice.actions;
export default uiSlice;
