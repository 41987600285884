import React, { useRef, useImperativeHandle } from "react";

import classes from "./DropDown.module.css";

const Dropdown = React.forwardRef((props, ref) => {
  const selectRef = useRef();

  const activate = () => {
    selectRef.current.focus();
  };

  useImperativeHandle(ref, () => ({
    focus: activate,
  }));

  return (
    <div
      className={`${props.className} ${classes.control} ${
        props.hasError === true ? classes.invalid : ""
      }`}
    >
      {!props.disableLabel && (
        <span className={classes.label}>
          <label htmlFor={props.id}>{props.label}</label> <p>*</p>
        </span>
      )}
      <select
        ref={selectRef}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
      >
        {props.options.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </select>
      <p className={classes.description}>{props.description}</p>
    </div>
  );
});

export default Dropdown;
