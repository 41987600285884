import React, { useState } from "react";
import classes from "./PopupItem.module.css";
import moment from "moment";
import { get_dddd } from "../../../../../../utils/ui-utils";

const DayItem = ({ data, className, onSelect, isSelected, type }) => (
  <div
    className={`${classes.dayItemContainer} ${className} ${
      isSelected && classes.selected
    }`}
    onClick={() => onSelect(data)}
  >
    <p className={classes.itemDay}>
      {type === "date" ? get_dddd(data.date) : moment(data.time).format("A")}
    </p>
    <p className={classes.itemDate}>
      {type === "date"
        ? moment(data.date).format("DD MMM")
        : moment(data.time).format("hh:mm")}
    </p>
  </div>
);

const PopupItem = ({ title, options, initialValue, onSelect, type }) => {;
  const [selectedItem, setSelectedItem] = useState(initialValue);
  if (!options || options.length === 0) {
    return <p>No slots available!</p>;
  }
  return (
    <div>
      <header className={classes.header}>
        <h2>{title}</h2>
      </header>
      <div className={classes.content}>
        {options.map((p) => {
          return (
            <DayItem
              key={p.id}
              data={p}
              isSelected={p.id === selectedItem.id}
              onSelect={(data) => {
                setSelectedItem(data);
                onSelect(data);
              }}
              type={type}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PopupItem;
