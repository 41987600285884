import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  discount: 0,
  isValid: true,
  error: null,
  code: "",
  isApplied: false,
  prompt: "Enter a promo code to get an additional discount.",
  isProcessing : false,
  discountedPlanId : null,
  discountOnPerMonthPlan : 0
};
const couponSlice = createSlice({
  name: "coupon",
  initialState: initialState,
  reducers: {
    reset :() => initialState,
    setDiscountedPlanId(state,actions){
      state.discountedPlanId = actions.payload
    },
    setDiscountOnPerMonthPlan(state, actions){
     state.discountOnPerMonthPlan = actions.payload
    },
    setDiscount(state, actions) {
      state.discount = actions.payload;
    },
    setIsProcessing(state, actions) {
      state.isProcessing = actions.payload;
    },
    setPrompt(state, actions) {
      state.prompt = actions.payload;
    },
    setIsValid(state, actions) {
      state.isValid = actions.payload;
    },
    setIsApplied(state, actions) {
      state.isApplied = actions.payload;
    },
    setError(state, actions) {
      state.error = actions.payload;
    },
    setCode(state, actions) {
      state.code = actions.payload;
    },
  },
});

export const couponActions = couponSlice.actions;
export default couponSlice;
