import React, { useEffect, useState } from "react";
import ProgramPricingItem from "./ProgramPricingItem/ProgramPricingItem";
import classes from "./ProgramPricing.module.css";
import Button from "../UI/Buttons/Button";
import { calculateRemainingDuration } from "../../utils/ui-utils";
import LmpPrompt from "./LmpPrompt/LmpPrompt";

const ProgramPricing = (props) => {
  const {
    lmp,
    plans,
    onProceed,
    className,
    discountedPlanId,
    discount,
    discountOnPerMonthPlan,
    onPlanSelect,
    btnText,
    disableSubmitBtn,
    shouldShowPrompt,
    title,
    theme,
    isRenewal
  } = props;

  let [selectedPlanId, setSelectedPlanId] = useState();

  const { remainingMonths, remainingDays, totalDaysRemaining } =
    calculateRemainingDuration(lmp);

  const prompt = `Basis your LMP you have ${remainingMonths} months and ${remainingDays} days.LMP is used to calculate the total amount for your program (Monthly price of the program ${totalDaysRemaining} days remaining till your EDD).If you wish to change your LMP, please go back.`;

  const onClickHandler = async () => {
    onProceed(selectedPlanId);
  };

  useEffect(() => {
    if (selectedPlanId) {
      onPlanSelect(selectedPlanId);
    }
  }, [selectedPlanId, onPlanSelect]);

  return (
    <div className={className}>
      <p className={classes.title}>{title}</p>
      {plans &&
        (plans || []).map((plan) => {
          const isSelected = plan._id === selectedPlanId;
          return (
            <ProgramPricingItem
              key={plan._id}
              className={classes["price-item"]}
              plan={plan}
              isSelected={isSelected}
              onSelectPlan={setSelectedPlanId}
              discount={discountedPlanId === plan._id ? discount : 0}
              discountOnPerMonthPlan={
                discountedPlanId === plan._id ? discountOnPerMonthPlan : 0
              }
              theme={theme}
            />
          );
        })}
      {shouldShowPrompt && <LmpPrompt lmp={lmp} isRenewal={isRenewal}></LmpPrompt>}
      {props.children}

      <Button
        disabled={
          !(selectedPlanId && selectedPlanId.trim().length > 0) ||
          disableSubmitBtn
        }
        onClick={onClickHandler}
        className={classes.btn}
      >
        {btnText}
      </Button>
    </div>
  );
};

export default ProgramPricing;
