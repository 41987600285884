import React from "react";
import FeedbackPage from "../components/FeedBackPage/FeedbackPage";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
function PaymentSuccessFeedBackPage() {
  let [searchParams] = useSearchParams();
  let wa = useSelector((state) => state.event.wa);
  const onBtnClickHandler = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.pruoo.pruoo_app";
  };

  const onWhatsAppInviteClickHandler = () => {
    window.location.href =
      wa || "https://play.google.com/store/apps/details?id=com.pruoo.pruoo_app";
  };

  return (
    <FeedbackPage
      isSuccess={true}
      title={"Payment Done!"}
      description={
        searchParams.get("isBookingClasses") === "true"
          ? wa ? "Thank you for booking iMumz Class, Kindly join our whatsApp Group for more personalized updates regarding the workshop" : "Thank you for booking iMumz Class, you'll receive the joining link over WhatsApp"
          : "Thank you for your payment. Our team will be in touch with you shortly to provide further assistance. If you have any questions in the meantime, please don't hesitate to reach out to us."
      }
      btnText={wa ? "Join WhatsApp Group!" : "Get The App"}
      onBtnClick={wa ? onWhatsAppInviteClickHandler : onBtnClickHandler}
      btnId={
        searchParams.get("isBookingClasses") === "true"
          ? `${searchParams.get("workshopName")}_payment_done`
          : `${searchParams.get("programType")}_payment_done`
      }
      showGroupInvite={wa}
    />
  );
}

export default PaymentSuccessFeedBackPage;
