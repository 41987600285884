import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../store/slices/user-slice";
import { slotsActions } from "../store/slices/slots-slice";
import { defer, Await, useLoaderData, useSearchParams } from "react-router-dom";
import { getAvailableSlots } from "../lib/api";
import { UiActions } from "../store/slices/ui-slice";
import store from "../store/store";
import SlotBooking from "../components/SlotBooking/SlotBooking";
import {
  appendFormDataIntoSheet,
  requestToBookASlot,
} from "../store/custom-actions-creator";

const SlotBookingPage = (props) => {
  const userData = useSelector((state) => state.users);
  const { isLoading, status } = useSelector((state) => state.ui);
  const { dateOptions, timeOptionsForTheSelectedDay, newDate, newTime } =
    useSelector((state) => state.slots);
  const dropDownOptions = useSelector((state) => state.event.dropDownOptions);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(UiActions.setShouldShowHeaders(false));
    dispatch(UiActions.setIsSlotBooking(true));
  }, [dispatch]);

  const [searchParams] = useSearchParams();
  const isInternalTeam = searchParams.get("internalTeam");

  const onSubmitHandler = (data) => {
    window.dataLayer.push(data);
    dispatch(userActions.saveFormData(data));
    dispatch(appendFormDataIntoSheet("slot-booking", [data.slotBooking]));
    dispatch(requestToBookASlot(isInternalTeam));
    console.log(data);
  };

  const onDateChangeHandler = (newDate) => {
    dispatch(slotsActions.setNewDate(newDate));
  };

  const onTimeChangeHandler = (newTime) => {
    dispatch(slotsActions.setNewTime(newTime));
  };

  const { event } = useLoaderData();
  return (
    <Suspense
      fallback={
        <p style={{ textAlign: "center", paddingTop: "50vh" }}>Loading...</p>
      }
    >
      <Await resolve={event}>
        {(slots) => {
          return (
            <SlotBooking
              userData={userData}
              onSubmit={onSubmitHandler}
              isLoading={isLoading}
              status={status}
              btnText={"BOOK NOW"}
              dateOptions={dateOptions}
              timeOptions={timeOptionsForTheSelectedDay}
              onDateChange={onDateChangeHandler}
              onTimeChange={onTimeChangeHandler}
              initialSelectedDate={newDate}
              initialSelectedTime={newTime}
              isInternalTeam={isInternalTeam}
              dropDownOptions={dropDownOptions}
            />
          );
        }}
      </Await>
    </Suspense>
  );
};

const fetchAvailableSlotsFromApi = async () => {
  const slots = await getAvailableSlots();
  if (!slots) {
    store.dispatch(UiActions.setError("No slots available!"));
    return;
  }
  store.dispatch(slotsActions.setDateOptions(slots.dateOptions));
  store.dispatch(slotsActions.setTimeOptions(slots.timeOptions));
  store.dispatch(slotsActions.setNewDate(slots.dateOptions[0]));
  return null;
};

export async function loader() {
  console.log("loader run ");
  return defer({ event: fetchAvailableSlotsFromApi() });
}
export default SlotBookingPage;
