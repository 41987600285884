import React from "react";
import RadioButton from "../../UI/Buttons/RadioButton";
import Card from "../../UI/Card/Card";
import classes from "./ProgramPricingItem.module.css";
import startRupee from "../../../resources/start_rupee.svg";
import { calculatePrice, deductDiscount } from "../../../utils/ui-utils";
function formatPrice(input) {

  let number;
  if (input.toString().includes("/month")) {
    number = parseInt(input.match(/\d+/)[0]);
    return `${number.toLocaleString()}/month`;
  } else {
    number = parseInt(input);
    return number.toLocaleString();
  }
}
const ProgramPricingItem = (props) => {
  const { plan, discount, isSelected, className, discountOnPerMonthPlan, theme} = props;
  const { title, caption, price, offer } = plan || {};

  const isDiscounted = +discount > 0;
  const innerContainerClassName = isSelected ? theme === "green" ? classes["green-text"] : classes["blue-text"] : "";
  const _className = `${
    isDiscounted ? classes.discountedContainer : classes.unSelected
  } ${className}`;

  const onClickHandler = () => {
    props.onSelectPlan(plan._id);
  };

  const innerContainer = (
    <Card
      className={innerContainerClassName}
      cardType={isSelected ? theme === "green" ? "green-border"  : "blue-border" : "light-border"}
      onClick={onClickHandler}
    >
      <div className={classes.content}>
        <div className={classes["plan-type"]}>
          <div>
            <RadioButton className={classes.btn} isSelected={isSelected}  theme={theme}/>
          </div>
          <div>
            <div className={classes["plan-details"]}>
              <p
                className={`${classes.title} ${
                  isSelected ? classes.selected : classes.unSelected
                }`}
              >
                {title}
              </p>
            </div>
            <p
              className={`${classes.caption} ${
                isSelected ? classes.selected : classes.unSelected
              }`}
            >
              {deductDiscount(caption, discountOnPerMonthPlan)}
            </p>
          </div>
        </div>
        <h3 className={classes.price}>₹{formatPrice(calculatePrice(price,discount,true))}</h3>
              {(plan.offer||"").trim().length >0 && !isDiscounted && <div className={classes.offer}>{offer}</div>}
      </div>
      {isDiscounted && (
        <>
          <hr className={`${classes.divider} solid`}></hr>
          <div className={classes.footer}>
            <p>Total promo code discount applied</p>
            <p>{`-₹${formatPrice(discount)}`}</p>
          </div>
        </>
      )}
    </Card>
  );

  const discountedPriceItem = (
    <Card className={_className}>
      {isDiscounted && (
        <div className={classes.label}>
          <img alt="rupee_symbol" src={startRupee} />
          <p>Yay! now pay only</p>
          <p className={classes.oldPrice}>{formatPrice(price.toString().split("/")[0])}</p>
          <p>
          ₹{formatPrice(calculatePrice(price,discount,true))}
          </p>
        </div>
      )}
      {innerContainer}
    </Card>
  );

  return discountedPriceItem;
};

export default ProgramPricingItem;
