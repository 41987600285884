import moment from "moment/moment";
export function calculateRemainingDuration(lmp) {
  if (!lmp)
    return {
      remainingMonths: "X",
      remainingDays: `Y`,
      totalDaysRemaining: "Z",
    };
  const lastPeriodDate = moment(lmp, "YYYY-MM-DD").toDate();
  const programEndDate = moment(lastPeriodDate).add(280, "days").toDate();
  const now = new Date();
  const differenceInMs = programEndDate.getTime() - now.getTime();
  const totalDaysRemaining = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

  const remainingMonths = Math.trunc(totalDaysRemaining / 30.5);
  const remainingDays = Math.trunc(totalDaysRemaining % 30.5);
  return {
    remainingMonths,
    remainingDays,
    totalDaysRemaining,
  };
}

export function getUpcomingWeekendString() {
  const today = moment();
  const thursday = today.clone().add(3, "day"); // modify this line
  const friday = today.clone().endOf("week").subtract(2, "day"); // modify this line
  const formatString = "Do MMMM";

  return `${friday.format(formatString)} & ${thursday.format(formatString)}`; // modify this line
}

export function filterObject(obj) {
  const filteredObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
        filteredObj[key] = obj[key];
      }
    }
  }
  return filteredObj;
}

export const calculatePrice = (price, discount, considerPeriod) => {
  const [amount, frequency] = price.toString().split("/");
  const calculatedPrice = Number(amount) - Number(discount);
  return considerPeriod && frequency
    ? `${calculatedPrice}/${frequency}`
    : `${calculatedPrice}`;
};

export function deductDiscount(str, amount) {
  
  const regex = /₹(\d+)/; // matches the rupee amount in the string
  const match = str.match(regex); // extracts the rupee amount from the string

  if (match) {
    const originalAmount = parseInt(match[1]); // converts the rupee amount to an integer
    
    console.log(str +  " orginal amm " +  originalAmount)
    const newAmount = originalAmount - amount; // deducts the discount amount from the original amount
    const formattedNewAmount = newAmount.toLocaleString("en-IN"); // formats the new amount with commas as per Indian numbering system
    const newStr = str.replace(regex, `₹${formattedNewAmount}`); // replaces the original rupee amount with the new amount in the string
    return newStr;
  } else {
    return str; // returns the original string if no rupee amount is found
  }
}

export function getCurrentDate() {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Add leading zeros if necessary
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Return date and time in the format of "dd-mm-yyyy hh:mm"
  return `${formattedDay}-${formattedMonth}-${year} ${formattedHours}:${formattedMinutes}`;
}

export function get_dddd(date) {
  let result = "";
  const _m = moment(date);
  const diffInHrs = _m.diff(moment(), "hours");
  const diffInDays = _m.diff(moment(), "days");
  if (diffInHrs <= 0) {
    result = "Today";
  } else if (diffInHrs > 0 && diffInDays === 0) {
    result = "Tomorrow";
  } else {
    result = _m.format("dddd");
  }
  return result;
}

export function validateDate(dateString) {
  if (
    dateString &&
    (!moment(dateString, "YYYY-MM-DD", true).isValid() ||
      moment(dateString, "YYYY-MM-DD").isBefore(moment(), "day"))
  ) {
    return moment().format("YYYY-MM-DD");
  } else {
    return dateString;
  }
}


export function getLmpLimits () {
  
  const today = moment().format("YYYY-MM-DD"); // Get today's date
  const minLMP = moment().subtract(280, "days").format("YYYY-MM-DD"); // Calculate the minimum date
  const maxLMP = today; //

  return {minLMP, maxLMP}
}