import { configureStore } from "@reduxjs/toolkit";

import userSlice from "./slices/user-slice";
import uiSlice from "./slices/ui-slice";
import plansSlice from "./slices/plans-slice";
import couponSlice from "./slices/coupon-slice";
import eventSlice from "./slices/event-slice";
import slotsSlice from "./slices/slots-slice";
import ipSlice from "./slices/ip-slice";
import prescriptionSlice from "./slices/prescription-slice";

const store = configureStore({reducer:{
  user: userSlice.reducer,
  ui: uiSlice.reducer,
  plans: plansSlice.reducer,
  coupon:couponSlice.reducer,
  event : eventSlice.reducer,
  slots: slotsSlice.reducer,
  ip : ipSlice.reducer,
  prescription : prescriptionSlice.reducer
}});

export default store;
