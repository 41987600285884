import classes from "./MainLayout.module.css";
import React from "react";

const MainLayout = (props) => {
  const { content, title, frame } = props;
  return (
    <div className={classes.container}>
      {frame}
      <div className={classes.formContainer}>
        <div className={classes.formTitleContainer}>
          <img
            alt="imumzIcon"
            className={classes.form_imumzIcon}
            src={"https://pages.imumz.com/static/media/imumz_icon_black.aaa55839f907a27e487c88f04afd4ff5.svg"}
          ></img>
          <p className={classes.form_title}>{title}</p>
        </div>
        {content}
      </div>
    </div>
  );
};

export default MainLayout;
