import React from "react";
import classes from "./PromoCode.module.css";
import Input from "../Input/SimpleInput";
import useInput from "../../../hooks/use-input";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const isNotEmpty = (value) => value?.trim() !== "";
const PromoCode = (props) => {
  const {
    className,
    couponHasError,
    description,
    cCode,
    enableApplyBtn,
    isProcessing,
    btnText,
    onSubmit,
    onRemoveCoupon
  } = props;

  const onRemoveCouponHandler =() => {
    reset();
    onRemoveCoupon()
  }

  const onSubmitHandler = () => {
    if (isValid && enableApplyBtn) {
      onSubmit(couponValue);
      // reset();
    }
  };
  const _className = `${classes.container} ${className}`;
  const {
    value: couponValue,
    isValid,
    hasError,
    valueChangeHandler: couponChangeHandler,
    reset,
  } = useInput(isNotEmpty, cCode || "");
  return (
    <div className={_className}>
      <div className={classes.inputContainer}>
        <p className={classes.label}>PROMO CODE</p>
        <Input
          type={"text"}
          id={"promoCode"}
          value={couponValue}
          onChange={couponChangeHandler}
          hasError={hasError || couponHasError}
          description={description}
          disableLabel={true}
          className={classes.input}
        />
      </div>
      <div className={classes.actionContainer}>
        {isProcessing ? (
          <LoadingSpinner className={classes.loader} color={"orange"} />
        ) : enableApplyBtn ? (
          <p
            className={`${classes.btn} ${
              isValid && enableApplyBtn
                ? classes.enable_btn
                : classes.disable_btn
            }`}
            onClick={onSubmitHandler}
          >
            {btnText || "APPLY"}
          </p>
        ) : (
          <p
            className={`${classes.btn} ${
              classes.enable_btn
            }`}
            onClick={onRemoveCouponHandler}
          >
            {"REMOVE"}
          </p>
        )}
      </div>
    </div>
  );
};

export default PromoCode;
