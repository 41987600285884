import React, { useRef, useImperativeHandle } from "react";

import classes from "./SimpleInput.module.css";

const SimpleInput = React.forwardRef(
  (
    {
      className,
      element: Element = "input",
      hasError = false,
      disableLabel = false,
      id,
      label,
      type,
      value,
      onChange,
      onBlur,
      placeholder,
      rows,
      description,
      showAsRequired,
      min,
      max
    },
    ref
  ) => {
    const inputRef = useRef();

    const activate = () => {
      inputRef.current.focus();
    };

    useImperativeHandle(ref, () => ({
      focus: activate,
    }));

    return (
      <div
        className={`${className} ${classes.control} ${
          hasError ? classes.invalid : ""
        }`}
      >
        {!disableLabel && (
          <span className={classes.label}>
            <label htmlFor={id}>{label} </label>
            {showAsRequired && <p>*</p>}
          </span>
        )}
        <Element
          ref={inputRef}
          type={type}
          id={id}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          rows={rows}
          min={min}
          max={max}
        />
        <p className={classes.description}>{description}</p>
      </div>
    );
  }
);

export default SimpleInput;
