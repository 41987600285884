import React, { useRef } from "react";
import ReactDOM from "react-dom";
import classes from "./Prescription.module.css";
import imumzIcon from "../../resources/imumIcon.svg";
import { useReactToPrint } from "react-to-print";
import DownloadButton from "../UI/Buttons/DowloadButton/DowloadButton";
import { useEffect } from "react";
const getReportsComponent = (report, index) => {
  const data = report.data;
  console.log(data)
  switch (report.type) {
    case "multi-para":
      return (
        <div key={index} className={classes.item}>
          <h3 className={classes.headingG}>{data.label}</h3>
          {data.content.paras.map((para, index) => {
            return <p key={index}>{para}</p>;
          })}
        </div>
      );
    case "table":
      return (
        <div key={index} className={classes.item}>
          <h3 className={classes.headingG}>{data.label}</h3>
          <table className={classes.table}>
            {data.content.rows.map((row) => {
              return (
                <tr className={classes.tr}>
                  {row.type === "th"
                    ? row.data.map((data, index) => (
                        <th className={classes.th} key={index}>
                          {data}
                        </th>
                      ))
                    : row.data.map((data, index) => (
                        <td className={classes.td} key={index}>
                          {data}
                        </td>
                      ))}
                </tr>
              );
            })}
          </table>
        </div>
      );
    case "bullet-point":
      return (
        <div key={index} className={classes.item}>
          <h3 className={classes.headingG}>{data.label}</h3>
          <ol className={classes.ol}>
            {data.content.points.map((point, index) => {
              return (
                <li key={index} className={classes.olLi}>
                  {point.main}
                  <ul key={index + "ul"} className={classes.ul}>
                    {point.sub &&
                      point.sub.map((subPoint, index) => (
                        <li className={classes.ulLi} key={index}>
                          {subPoint}
                        </li>
                      ))}
                  </ul>
                </li>
              );
            })}
          </ol>
        </div>
      );

    default:
      return <div>{JSON.stringify(data.label)}</div>;
  }
};

const Prescription = ({ data }) => {
  console.log(data)
  const contentToPrint = useRef();
  const genPdf = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "prescription" + Date.now(),
  });

  // useEffect(() => genPdf(), []);

  return (
    <div className={classes.container}>
      <div id="_report2" ref={contentToPrint} className={classes.body}>
     
        <hr className={classes.divider} />
        <p className={classes.text2}>Reg No. {data.token}</p>
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            <h3 className={classes.headingG}>Plan generated by</h3>
            <p className={classes.text}>{data.doctorName}</p>
          </div>
          
          <img className={classes.icon} alt="imumz_icon" src={imumzIcon} />
        </div>
        <h1 className={classes.headingB}>Care plan for {data.patientName}</h1>
        {data.reports.map((report, index) => {
          return getReportsComponent(report, index);
        })}
      </div>
      <div className={classes.fabContainer}>
        <DownloadButton
          className={classes.fab}
          onClick={() => {
            genPdf();
          }}
        />
      </div>
      ,
    </div>
  );
};

export default Prescription;
