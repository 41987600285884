import classes from "./MainHeader.module.css";
import backArrow from "./back_arrow.svg";
import IconButton from "../UI/Buttons/IconButton";
import { useMatch } from "react-router";

const MainHeader = (props) => {
   const isSuccessPage = !useMatch('/success-feedback')
   const isBaseUrl = !useMatch("/") 
   const isGSReg =  !useMatch("/garbhsanskar-class");
  const shouldShowBackBtn  =  isBaseUrl && isGSReg  && isSuccessPage
  const onBckPressedHandler = () => {
    props.onBackPressed()
  };
  return (
   <div className={classes['main-header']}>
     {shouldShowBackBtn && (
          <IconButton
            className={classes["back-btn"]}
            icon={backArrow}
            text={"Back"}
            onClick={onBckPressedHandler}
          />
        )}
   </div>
  );
};

export default MainHeader;
