import React from "react";
import classes from "./RegistrationForm.module.css";
import imumzIcon from "../../resources/imumIcon.svg";
import ContactInfoForm from "../ContactInfoForm/ContactInfoForm";
const RegistrationForm = (props) => {
  const {
    onSubmit,
    userData,
    date,
    time,
    isLoading,
    status,
    price,
    title,
    disabledPregDuration,
    dropDownOptions,
    sideImage,
    showOccupationDropDown,
    trackParenting,
    parentingLimit,
    boldTitle,
  } = props;
  return (
    <div className={classes.background}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={sideImage} alt="backgroundImg" />
      </div>
      <div className={classes.card}>
        <img className={classes.icon} src={imumzIcon} alt="imumz_icon" />
        {boldTitle && <p className={classes.boldTitle}>{boldTitle}</p>}
        {title && <p className={classes.title}>{title}</p>}
        {date && <h5>{`Date: ${date}`}</h5>}
        {time && <h5>{`Time: ${time}`}</h5>}
        <ContactInfoForm
          onSubmit={onSubmit}
          userData={userData}
          isLoading={isLoading}
          status={status}
          isRegForm={true}
          className={classes.contactInfoForm}
          btnText={`Pay ₹${price} Now`}
          disabledPregDuration={disabledPregDuration}
          showOccupationDropDown={showOccupationDropDown}
          dropDownOptions={dropDownOptions}
          trackParenting={trackParenting}
          parentingLimit={parentingLimit}
        />
        <p className={classes.bottomText}>Made with 💗 by iMumz</p>
      </div>
    </div>
  );
};

export default RegistrationForm;
