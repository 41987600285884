import React from "react";
import classes from "./FeedbackPage.module.css";
import doneIcon from "./done-icon.png";
import errorIcon from "./error-icon.png";
import Button from "../UI/Buttons/Button";
import FullScreenPageCardContainer from "../UI/FullScreenCardContainer/FullScreenCardContainer";
import LoadingSpinner from "../UI/LoadingSpinner/LoadingSpinner";
import whatsAppIcon from "../../resources/whatsappIcon.svg";
const FeedbackPage = (props) => {
  const {
    isLoading,
    title,
    description,
    isSuccess,
    btnText,
    onBtnClick,
    btnId,
    showGroupInvite,
  } = props;

  return (
    <FullScreenPageCardContainer>
      {isLoading && <LoadingSpinner className={classes.loader} />}
      {!isLoading && (
        <div className={classes.feedback}>
          <img
            className={classes.image}
            src={isSuccess ? doneIcon : errorIcon}
            alt={isSuccess}
          />
          <h1>{title}</h1>
          <p className={classes.description}>{description}</p>

          {showGroupInvite ? (
            <button
              onClick={onBtnClick}
              className={classes.waButton}
              id={btnId}
            >
              <img src={whatsAppIcon} alt="join whatsapp group" />
             {btnText } 
            </button>
          ) : (
            <>
              {btnText && (
                <Button className={classes.btn} onClick={onBtnClick} id={btnId}>
                  {btnText}
                </Button>
              )}
            </>
          )}

          <div className={classes.contactDetails}>
            <p>Contact at :</p>
            <a href="mailto: support@imumz.com">support@imumz.com</a>
          </div>
        </div>
      )}
    </FullScreenPageCardContainer>
  );
};

FeedbackPage.propTypes = {};

export default FeedbackPage;
