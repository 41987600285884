import PhoneInput from "react-phone-number-input";
import "./PhoneNumberInput.css";
import React, { useRef, useImperativeHandle } from "react";
import classes from "../SimpleInput.module.css";

const PhoneNumberInput = React.forwardRef((props, ref) => {
  const inputRef = useRef();

  const activate = () => {
    inputRef.current.focus();
  };

  useImperativeHandle(ref, () => {
    return {
      focus: activate,
    };
  });

  return (
    <div
      className={`${classes.control} ${
        props.hasError === true ? classes.invalid : ""
      }`}
    >
      <span className={classes.label}>
        <label htmlFor={props.id}>{props.label}</label> <p>*</p>
      </span>
      <PhoneInput
        className="PhoneInputInput"
        // placeholder="Enter phone number"
        defaultCountry="IN"
        value={props.value}
        onChange={(phone) => props.onChange({ target: { value: phone } })}
        onBlur={props.onBlur}
      />
      <p className={classes.description}>{props.description}</p>
    </div>
  );
});

export default PhoneNumberInput;
