import React from "react";
import classes from "./WcpForm.module.css";
import MainLayout from "../UI/MainLayout/MainLayout";
import ContactInfoForm from "../ContactInfoForm/ContactInfoForm";
import LayoutFrame1 from "../UI/MainLayout/LayoutFrames/LayoutFrame1";
import ladyBabyheart from "../../resources/LadyBabyHeart.svg";
const WcpForm = (props) => {
  const { onSubmit, userData } = props;
  return (
    <MainLayout
      title={"Let's get you started"}
      content={
        <ContactInfoForm
          className={classes.form}
          onSubmit={onSubmit}
          userData={userData}
          btnText={"NEXT"}
        />
      }
      frame={
        <LayoutFrame1
          title="WombCare"
          subtitle="PROGRAM"
          whiteText1="Make your pregnancy easier with"
          colouredText="Yoga, Nutrition & Garbha Sanskar Coaches"
          whiteText2="by your side."
          image={ladyBabyheart}
          theme="blue"
        />
      }
    />
  );
};

export default WcpForm;
