import React from "react";
import FeedbackPage from "../components/FeedBackPage/FeedbackPage";
import { useSearchParams } from "react-router-dom";
function SlotBookingFeedBackPage() {
  let [searchParams] = useSearchParams();

  const onBtnClickHandler = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.pruoo.pruoo_app";
  };

  return (
    <FeedbackPage
      isSuccess={searchParams.get("isSlotBooked") === "true"}
      title={"Slot Booked!"}
      description={
           "ThanK you for booking a slot with us. Our team will be in touch with you shortly to provide further assistance. If you have any questions in the meantime, please don't hesitate to reach out to us."
      }
      btnText={"Get The App"}
      onBtnClick={onBtnClickHandler}
      btnId={"slot_booked"}
    />
  );
}

export default SlotBookingFeedBackPage;
