import axios from "axios";
import { filterObject } from "../utils/ui-utils";

// * required body params
// const userId = request.body["userId"];
// const selectedPlanId = request.body["selectedPlanId"];
// optional appliedCoupon
export async function createOrder(userData) {
   
  let _data = filterObject(userData)

  const url =
    "https://us-central1-pruoo-app.cloudfunctions.net/createOrderForWebsitePayment";
  const options = {
    method: "POST",
    headers: { "content-type": "application/json" },
    data: JSON.stringify({ ..._data}),
    url,
  };
  const response = await axios(options);
  const data = response.data;

  if (data.result !== "success") {
    throw new Error(data.message || "Could not create quote.");
  }
  const { order, amount } = data;

  return { order, amount };
}

export async function appendDataIntoSheet(userData) {
  console.log(JSON.stringify({ ...userData }))
  const url =
    "https://us-central1-pruoo-app.cloudfunctions.net/appendDataIntoSheet";
  const options = {
    method: "POST",
    headers: { "content-type": "application/json" },
    data: JSON.stringify({ ...userData }),
    url,
  };
  await axios(options);
}

export async function validateCoupons(couponCode, selectedPlanId, lmp, programStartDate) {
  try {
    const url =
      "https://us-central1-pruoo-app.cloudfunctions.net/validateCouponCodes";
    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      data: JSON.stringify({ couponCode, selectedPlanId, lmp,programStartDate }),
      url,
    };
    const response = await axios(options);

    if (response.status === 200) {
      const data = response.data;
      return {
        ...data,
        isValid: true,
        discount: data.discount || 0,
        message: "Coupon Applied!!",
      };
    }
    return {
      isValid: false,
      discount: 0,
      message: response?.data?.message || "Invalid Coupon!!",
      discountedPlanId: null,
      discountOnPerMonthPlan: 0,
    };
  } catch (err) {
    console.log(err);
    return {
      isValid: false,
      discount: 0,
      message: "Invalid Coupon!!",
      discountedPlanId: null,
      discountOnPerMonthPlan: 0,
    };
  }
}

export async function getAvailableSlots() {
  try {
    const url =
      "https://us-central1-pruoo-app.cloudfunctions.net/getAvailableSlots";
    const options = {
      method: "POST",
      withCredentials: false,
      headers: { "content-type": "application/json" },
      url,
    };
    const response = await axios(options);
    const data = response.data;

    if (data.result !== "success") {
      throw new Error(data.message || "Could not fetch slots.");
    }
    return data.data;
  } catch (err) {
    console.log(err);
  }
}

export const bookSlot = async (data) => {
  try {
    const url =
      "https://us-central1-pruoo-app.cloudfunctions.net/websiteRequestToBookSlot";
    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      data: JSON.stringify(data),
      url,
    };
    const response = await axios(options);
    const _data = response.data;

    if (_data.result !== "success") {
      throw new Error(_data.message || "Could not book slot.");
    }
    console.log(JSON.stringify(_data));
    return _data;
  } catch (err) {
    console.log(err);
    return {
      result: "failed",
      message: "Booking Failed, Please Try Again Later!",
    };
  }
};

export const getIpData = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    if (!response.ok) {
      throw new Error("Network response was not OK");
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error in fetching ip data:", error);
  }
};
