import React from "react";
import Card from "../Card/Card";
import classes from "./FullScreenCardContainer.module.css";

const FullScreenCardContainer = (props) => {
  return (
    <div className={classes.container}>
      <Card className={classes.Card}>
      {props.children}
      </Card>
    </div>
  );
};

export default FullScreenCardContainer;
