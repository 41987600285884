import React, { useRef, useState, useImperativeHandle, useEffect } from "react";

import classes from "./SlotPicker.module.css";
import PopUp from "./PopUp/PopUp";
import moment from "moment";

const SlotPicker = React.forwardRef((props, ref) => {
  const {
    timeOptions,
    dateOptions,
    onChange,
    onBlur,
    onDateChange,
    onTimeChange,
    initialSelectedDate,
    initialSelectedTime,
    value,
    className,
    hasError,
    type,
    id,
    description,
  } = props;

  const [inputValue, setInputValue] = useState(
    value ||
      (initialSelectedTime?.time?.trim()?.length > 0
        ? moment(initialSelectedTime.time).format("DD MMM hh:mm a")
        : "")
  );

  console.log(
    initialSelectedTime?.time?.trim()?.length > 0
      ? moment(initialSelectedTime.time).format("DD MMM hh:mm a")
      : ""
  );

  const inputRef = useRef();

  useEffect(() => {
    console.log("value change...");
    const value =
      initialSelectedTime?.time?.trim()?.length > 0
        ? moment(initialSelectedTime.time).format("DD MMM hh:mm a")
        : "";
    onChange({
      target: {
        value: value,
      },
    });
    setInputValue(value);
  }, [initialSelectedTime, onChange]);

  const [showPopup, setShowPopup] = useState();

  const activate = () => {
    //Open portal
    inputRef.current.focus();
    setShowPopup(true);
  };

  useImperativeHandle(ref, () => {
    return {
      focus: activate,
    };
  });

  return (
    <>
      {showPopup && (
        <PopUp
          onClose={() => setShowPopup(false)}
          onConfirm={(arg) => {
            // const _input =
            //   arg?.initialSelectedTime?.time?.trim()?.length > 0
            //     ? moment(initialSelectedTime.time).format("DD MMM hh:mm a")
            //     : "";

            // setInputValue(_input);
            setShowPopup(false);
          }}
          timeOptions={timeOptions}
          dateOptions={dateOptions}
          onDateChange={onDateChange}
          onTimeChange={onTimeChange}
          initialSelectedDate={initialSelectedDate}
          initialSelectedTime={initialSelectedTime}
        />
      )}
      <div
        className={`${className} ${classes.control} ${
          hasError === true ? classes.invalid : ""
        }`}
        onSelect={() => setShowPopup(true)}
      >
        {!props.disableLabel && (
          <span className={classes.label}>
            <label htmlFor={id}>{props.label}</label> <p>*</p>
          </span>
        )}
        <input
          ref={inputRef}
          type={type}
          id={id}
          value={inputValue}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={true}
        />
        <p className={classes.description}>{description}</p>
      </div>
    </>
  );
});

export default SlotPicker;
