import { useState, useEffect } from "react";

const useDeviceDetails = () => {
  const [deviceDetails, setDeviceDetails] = useState({
    model: "",
    brand: "",
  });

  useEffect(() => {
    const userAgent = navigator?.userAgent || "";
    const model = userAgent.match(/\((.*?)\)/)?.[1]?.split(";")[1]?.trim() || "";
    const brand = userAgent.match(/\((.*?)\)/)?.[1]?.split(";")[0]?.trim() || "";

    setDeviceDetails({
      model,
      brand,
    });
  }, []);

  return deviceDetails;
};

export default useDeviceDetails;

