import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import MainHeader from "../components/MainHeader/MainHeader";
import { couponActions } from "../store/slices/coupon-slice";
import { UiActions } from "../store/slices/ui-slice";
import { userActions } from "../store/slices/user-slice";
import { traceIpAddress } from "../store/custom-actions-creator";
import useDeviceDetails from "../hooks/use-deviceDetails";
import { validateDate } from "../utils/ui-utils";

function RootLayout() {
  // const navigation = useNavigation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { model, brand } = useDeviceDetails();
  const userState = useSelector((state) => state.user);
  const onBackPressed = () => {
    const shouldClearCouponStateAndPaymentsOrder =
      location.pathname.includes("/plans");
    if (shouldClearCouponStateAndPaymentsOrder) {
      dispatch(UiActions.setCouponInputVisibility(false));
      dispatch(UiActions.setShouldExecuteRazorpayScript(false));
      dispatch(
        userActions.appendPropsInExtras({
          order: null,
          amount: 0,
          appliedCoupon: null,
        })
      );
      dispatch(couponActions.reset());
    }
    navigate(".." + location.search, { relative: "path" });
  };

  // *  save data from query params...
  useEffect(() => {
    // const isRenewal = searchParams.get("isRenewal") === "true";
    const isCouponInputVisible =
      searchParams.get("isCouponInputVisible") === "true";
    dispatch(UiActions.setCouponInputVisibility(isCouponInputVisible));
    // alert(window.navigator.userAgent);
    Object.keys(userState).map((key) => {
      let val = searchParams.get(key);

      if (key === "programStartDate") {
        val = validateDate(val);
      }
      const toSave = { key: key, value: val };
      val && dispatch(userActions.saveValueWithKey(toSave));
      return toSave;
    });

    dispatch(
      userActions.appendPropsInExtras({
        utm_campaign: searchParams.get("utm_campaign"),
        utm_source: searchParams.get("utm_source"),
        utm_content: searchParams.get("utm_content"),
        pre_sales_owner: searchParams.get("pre_sales_owner"),
        model,
        brand,
      })
    );
    dispatch(traceIpAddress());
  }, [searchParams, dispatch, brand, model]);

  //...redirecting user after payment done!
  const {
    isLoading,
    isPaymentDone,
    isBookingClasses,
    shouldShowHeader,
    isSlotBooked,
    programType,
  } = useSelector((state) => state.ui);
  useEffect(() => {
    searchParams.set("isBookingClasses", isBookingClasses);
    searchParams.set("isSlotBooked", isSlotBooked);

    if (!isLoading && (isPaymentDone || isSlotBooked)) {
      if (isSlotBooked) {
        dispatch(userActions.reset());
        dispatch(UiActions.reset());
        navigate("/SlotBookingFeedBackPage?" + searchParams.toString());
        return;
      }
      searchParams.set("programType", programType);
      navigate(`/success-feedback?${searchParams.toString()}`, {
        replace: true,
      });
      dispatch(UiActions.reset());
    }
  }, [
    isLoading,
    isPaymentDone,
    isBookingClasses,
    navigate,
    isSlotBooked,
    dispatch,
    programType,
  ]);

  const isHome = useMatch("/");

  return (
    <>
      {shouldShowHeader && !isHome && (
        <MainHeader onBackPressed={onBackPressed} />
      )}
      <div id="_report" className="mainLayout">
        <Outlet />
      </div>
    </>
  );
}

export default RootLayout;
