import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    ip: "",
    network: "",
    version: "",
    city: "",
    region: "",
    region_code: "",
    country: "",
    country_name: "",
    country_code: "",
    country_code_iso3: "",
    country_capital: "",
    country_tld: "",
    continent_code: "",
    in_eu: "",
    postal: "",
    latitude: "",
    longitude: "",
    timezone: "",
    utc_offset: "",
    country_calling_code: "",
    currency: "",
    currency_name: "",
    languages: "",
    country_area: "",
    country_population: "",
    asn: "",
    org: "",
  },
};
const ipSlice = createSlice({
  name: "ip",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setData(state, actions) {
      state.data = actions.payload;
      return state;
    },
  },
});

export const ipActions = ipSlice.actions;
export default ipSlice;
