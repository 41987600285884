import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../store/slices/user-slice";
import WcpForm from "../components/WcpForm/WcpForm";
import { appendFormDataIntoSheet } from "../store/custom-actions-creator";

const UserForm = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  const onSubmitHandler = (userData) => {
    window.dataLayer.push(userData)
    dispatch(userActions.saveFormData(userData));
    const searchParams = new URLSearchParams(search);
    searchParams.set("lmp", userData?.lmp || "");
    const newSearch = searchParams.toString();
    dispatch(appendFormDataIntoSheet("wombcare_payments"))
    navigate(`/plans?${newSearch}`);
    console.log(userData);
  };

  return <WcpForm onSubmit={onSubmitHandler} userData={userData} />;
};

export default UserForm;
