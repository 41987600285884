import { createBrowserRouter, RouterProvider } from "react-router-dom";
import UserForm from "./pages/WcpUserForm";
import WcpPlans, { loader as wcpPlansLoader } from "./pages/WcpPlans";
import StandardPlans, {
  loader as standardPlansLoader,
} from "./pages/StandardPlans";
import RootLayout from "./pages/Root";
import PaymentSuccessFeedBackPage from "./pages/PaymentSuccessFeedBackPage";
import SlotBookingPage, { loader as slotsloader } from "./pages/SlotBooking";
import SlotBookingFeedBackPage from "./pages/SlotBookingFeedBackPage";
import Workshop, { loader as eventLoader } from "./pages/Workshop";
import SpUserForm from "./pages/SpUserForm";
import SuperParentingForm, {
  loader as superParentingLoader,
} from "./pages/SuperParentingForm";
import PrescriptionPage, { loader as preconceptionLoader } from "./pages/PrescriptionPage";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        {
          index: true,
          element: <UserForm />,
        },
        {
          path: "superParenting",
          loader: superParentingLoader,
          element: <SuperParentingForm />,
          query: {
            plan_duration: "3m",
          },
        },
        {
          path: "standard",
          children: [
            { index: true, element: <SpUserForm /> },
            {
              path: "plans",
              id: "std-program-plans",
              loader: standardPlansLoader,
              element: <StandardPlans />,
              query: {
                lmp: "2023-07-19",
              },
            },
          ],
        },
        {
          path: "plans",
          id: "program-plans",
          loader: wcpPlansLoader,
          children: [
            {
              index: true,
              element: <WcpPlans />,
            },
          ],
        },
        {
          path: "workshop",
          element: <Workshop />,
          loader: eventLoader,
        },
        {
          path: "success-feedback",
          element: <PaymentSuccessFeedBackPage />,
        },
        {
          path: "slot-booking",
          element: <SlotBookingPage />,
          loader: slotsloader,
        },
        {
          path: "SlotBookingFeedBackPage",
          element: <SlotBookingFeedBackPage />,
        },
        {
          path: "prescription",
          element: <PrescriptionPage />,
          loader : preconceptionLoader
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
