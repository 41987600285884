import React from "react";

import { calculateRemainingDuration } from "../../../utils/ui-utils";
import classes from "./LmpPrompt.module.css";
const LmpPrompt = ({ lmp, className, isRenewal}) => {
  const { remainingMonths, remainingDays, totalDaysRemaining } =
    calculateRemainingDuration(lmp);

  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.header}>
        <p className={classes.headLight}>{`Basis your LMP you have :`}</p>
        <p
          className={classes.headBold}
        >{`${remainingMonths} months & ${remainingDays} days.`}</p>
      </div>
      <div className={classes.body}>
        <p>{`LMP is used to calculate the total amount for your program (Monthly price of the program ${totalDaysRemaining} days remaining till your EDD)`}</p>
      </div>
      <div className={classes.footer}>
      {  !isRenewal && <p>If you wish to change your LMP, please go back.</p>}
      </div>
    </div>
  );
};

export default LmpPrompt;
