import React from "react";

import classes from "./RadioButton.module.css";

const RadioButton = (props) => {
  const _className = `${classes.outer} ${props.theme === "green" ? classes.greenOut : classes.blueOut} ${props.className || "" }`;
  return (
    <div className={_className}>
      {props.isSelected && <div className={`${classes.inner} ${props.theme === "green" ? classes.greenIn : classes.blueIn}`}></div>}
    </div>
  );
};

export default RadioButton;
