import React from "react";
import classes from "./IconButton.module.css";
const IconButton = (props) => {
  return (
    <div className={classes.nav + ` ${props.className}`} onClick={props.onClick}>
       <div className={classes.img}></div>
      {props.text && <p>{props.text}</p>}
    </div>
  );
};

export default IconButton;
