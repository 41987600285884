import ProgramPricing from "../ProgramPricing/ProgramPricing";
import LayoutFrame2 from "../UI/MainLayout/LayoutFrames/LayoutFrame2";
import MainLayout from "../UI/MainLayout/MainLayout";
import PromoCode from "../UI/PromoCode/PromoCode";
import classes from "./PlanSelection.module.css";
import pregMums from "../../resources/preg_mums.svg";
import React from "react";

const PlanSelection = (props) => {
  const {
    onProceed,
    lmp,
    plans,
    onCouponSubmit,
    enableCouponBtn,
    isApplyingCoupon,
    cCode,
    couponHasError,
    discountedPlanId,
    discount,
    promoCodePrompt,
    discountOnPerMonthPlan,
    onPlanSelect,
    btnText,
    disableSubmitBtn,
    onRemoveCoupon,
    shouldShowPrompt,
    title,
    frame,
    theme,
    isRenewal,
    isCouponInputVisible,
  } = props;
  return (
    <MainLayout
      title={"Program Pricing"}
      frame={frame}
      content={
        <div className={classes.container}>
          <ProgramPricing
            onProceed={onProceed}
            lmp={lmp}
            plans={plans}
            discountedPlanId={discountedPlanId}
            discount={discount}
            discountOnPerMonthPlan={discountOnPerMonthPlan}
            onPlanSelect={onPlanSelect}
            btnText={btnText}
            disableSubmitBtn={disableSubmitBtn}
            shouldShowPrompt={shouldShowPrompt}
            title={title}
            theme={theme}
            isRenewal={isRenewal}
          >
            {isCouponInputVisible && (
              <PromoCode
                className={classes.promoCode}
                description={promoCodePrompt}
                couponHasError={couponHasError}
                cCode={cCode}
                enableApplyBtn={enableCouponBtn}
                isProcessing={isApplyingCoupon}
                onSubmit={onCouponSubmit}
                onRemoveCoupon={onRemoveCoupon}
              />
            )}
          </ProgramPricing>
        </div>
      }
    />
  );
};

export default PlanSelection;
